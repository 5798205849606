import { defineStore } from 'pinia';
import { customerService } from '@/services';
export const useCustomersStore = defineStore('customers-store', {
  state() {
    return {
      stats: {
        totalCustomers: 0,
        verifiedCustomers: 0,
      },
      customers: [],
      customer: undefined
    };
  },
  actions: {
    async getCustomerStats() {
      const response = await customerService.getCustomersStats();
      this.stats = response.data;
    },
    async getCustomers() {
      try {
        const response = await customerService.getCustomers();
        this.$state.customers = response.data;
      } catch (error) {
        throw error;
      }
    },
    async getCustomerById(customerId) {
      try {
        const response = await customerService.get(customerId);
        this.$state.customer = response.data;
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  },
});
