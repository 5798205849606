import { _axios } from '@/plugins/axios';
import { ApiError } from '@/utils/error';
import { TokenService } from './TokenService';

export class OrderService {
  constructor() {
    this.axios = _axios;
    this.tokenService = new TokenService();
  }

  async getOrders(offset, limit, token) {
    const authToken = token || this.tokenService.get();
    let url = `orders`;
    if (offset && limit) {
      url = `orders?Limit=${limit}&Offset=${offset}`;
    }
    try {
      return await this.axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getOrderStats(token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`orders/stats`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async placeOrder(data, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .post(`orders`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async addNote(orderReference, data, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .post(`orders/${orderReference}/notes`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async startOrder(orderReference, data, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(`orders/${orderReference}/technicians/checklist-start`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async completeOrder(orderReference, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(
          `orders/${orderReference}/technicians/checklist-complete`,
          {},
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async assignDifficultyLevel(orderReference, data, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(`orders/${orderReference}/difficulty`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async cancel(orderRef, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(
          `orders/${orderRef}/status`,
          {
            status: 'Cancelled',
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async deleteOrder(orderId, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .delete(`orders/${orderId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async deleteNote(orderId, noteId, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .delete(`orders/${orderId}/notes/${noteId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async deleteAllPhotos(orderId, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .delete(`orders/${orderId}/photos`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async updateOrder(orderId, data, token) {
    const authToken = token || this.tokenService.get();
    try {
      await this.axios.put(`orders/${orderId}`, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      return true;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw new Error('Order not found');
      }
      throw ApiError.toError(error);
    }
  }

  async deletePhoto(orderId, photoId, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .delete(`orders/${orderId}/photos/${photoId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async accept(orderRef, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(
          `orders/${orderRef}/status`,
          {
            status: 'Accepted',
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async ship(orderRef, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(
          `orders/${orderRef}/status`,
          {
            status: 'Shipped',
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async complete(orderRef, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(
          `orders/${orderRef}/status`,
          {
            status: 'Completed',
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async assignOrder(orderRef, data, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(`orders/${orderRef}/technicians`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getAssignedTechnicians(orderRef, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`orders/${orderRef}/technicians`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async reject(orderRef, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(
          `orders/${orderRef}/status`,
          {
            status: 'Rejected',
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async reopen(orderRef, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(
          `orders/${orderRef}/status`,
          {
            status: 'InProgress',
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async receiveWatch(orderRef, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(
          `orders/${orderRef}/status`,
          {
            status: 'Received',
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async addItemToFixNote(orderRef, payload, token) {
    const authToken = token || this.tokenService.get();

    const note = JSON.stringify({ note: payload.note });

    try {
      return await this.axios
        .put(`orders/${orderRef}/items-to-fix/${payload.id}/notes`, note, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async patchProduct(orderRef, payload, token) {
    const authToken = token || this.tokenService.get();

    console.debug('patchProduct()', { orderRef, payload });

    const keys = Object.keys(payload);

    const jsonDoc = [];

    keys.forEach((key) =>
      jsonDoc.push({
        op: 'replace',
        path: `/${key}`,
        value: payload[key],
      })
    );

    console.debug('patchProduct()', { jsonDoc });

    try {
      return await this.axios
        .patch(`orders/${orderRef}/products`, jsonDoc, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async technicianItemToFixAction(orderRef, itemToFixId, action, token) {
    const authToken = token || this.tokenService.get();
    try {
      const payload = JSON.stringify({
        type: action,
      });

      return await this.axios
        .put(`orders/${orderRef}/items-to-fix/${itemToFixId}/action`, payload, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getOrderByRef(ref, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`orders/${ref}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getOrderForTechnician(token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`orders?Assigned=true`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async filter(query, token) {
    const authToken = token || this.tokenService.get();
    let queryStringGenerator = [];

    Object.keys(query).forEach((key, index) => {
      queryStringGenerator.push(`${key}=${query[key]}`);
    });

    try {
      return await this.axios
        .get(`orders?${queryStringGenerator.join('&')}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async filterToExport(by, value, token) {
    const authToken = token || this.tokenService.get();
    try {
      switch (by) {
        case 'ref':
          if (!value.reference) return;
          return await this.axios
            .get(`orders/export?Reference=${value.reference}`, {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            })
            .then((res) => res.data);
        case 'status':
          if (!value.status) return;
          return await this.axios
            .get(`orders/export?Status=${value.status}`, {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            })
            .then((res) => res.data);
        case 'date-status':
          if (!value.fromDate || !value.toDate || !value.status) return;
          return await this.axios
            .get(
              `orders/export?FromDate=${value.fromDate}&ToDate=${value.toDate}&Status=${value.status}`,
              {
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
              }
            )
            .then((res) => res.data);
        case 'date':
          if (!value.fromDate || !value.toDate) return;
          return await this.axios
            .get(
              `orders/export?FromDate=${value.fromDate}&ToDate=${value.toDate}`,
              {
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
              }
            )
            .then((res) => res.data);

        default:
          throw new Error('Unsupported filter type');
      }
    } catch (error) {
      throw ApiError.toError(error);
    }
  }
}

export const orderService = new OrderService();
